import { getCurrentShopLocale } from './common/commonUtils';
import { DefaulText } from './domain';
import netInventory from './netInventory';
export default function createOnlineStoreLocation(
  inventoryLocations,
  settings,
  inventoryManagement
) {
  if (settings.onlineLocation) {
    if (
      inventoryManagement &&
      settings.untracked &&
      settings.showOnlineForUntracked
    ) {
      return;
    }
    if (settings.untracked && !inventoryManagement) {
      return getOnlineLocation(undefined, settings, settings.untracked);
    }
    let totalQty = 0;
    const offlineLocations = settings.offlineLocations || {};
    const validLocations = inventoryLocations.filter(
      inventoryLocation => inventoryLocation.quantity !== null
    );

    if (validLocations.length === 0) {
      return null;
    }
    const updatedLocations = netInventory(validLocations, settings);
    for (let i = 0; i < updatedLocations.length; i++) {
      totalQty +=
        (updatedLocations[i].quantity < 0 && !settings.calcNetInventory) ||
        offlineLocations[updatedLocations[i].location.id]
          ? 0
          : updatedLocations[i].quantity;
    }
    return getOnlineLocation(totalQty, settings);
  }
}

export function hasUntrackedOnlineLocation(settings) {
  return settings.onlineLocation && settings.untracked;
}

function getOnlineLocation(totalQty, settings, untracked) {
  const shopLocale = getCurrentShopLocale();
  let onlineLocationLabelText = settings.onlineLocationLabel;
  if (
    settings.onlineLocationLabel_locales &&
    Object.keys(settings.onlineLocationLabel_locales).length > 0 &&
    settings.onlineLocationLabel_locales[shopLocale]
  ) {
    onlineLocationLabelText = settings.onlineLocationLabel_locales[shopLocale];
  }
  return {
    quantity: totalQty,
    location: {
      id: 'online',
      untracked,
      name: onlineLocationLabelText || DefaulText.OnlineLocation
    }
  };
}
