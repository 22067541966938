/* eslint-disable no-empty */
export default function netInventory(inventoryLocations, settings) {
  if (settings && settings.calcNetInventory) {
    let total = 0;
    for (let i = 0; i < inventoryLocations.length; i++) {
      total += inventoryLocations[i].quantity;
    }

    if (total <= 0) {
      for (let i = 0; i < inventoryLocations.length; i++) {
        inventoryLocations[i].quantity = 0;
      }
    }
  }
  return inventoryLocations;
}
