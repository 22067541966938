import loadScript from './common/loadScript';
import { isMobile } from './utils';

export function formatPhoneNumbers(settings, inventoryLocations) {
  loadScript(
    'https://unpkg.com/libphonenumber-js@1.10.14/bundle/libphonenumber-min.js',
    function() {
      updatePhoneNumbers(settings, inventoryLocations);
    }
  );
}

function updatePhoneNumbers(settings, inventoryLocations) {
  for (let i = 0; i < inventoryLocations.length; i++) {
    const location = inventoryLocations[i].location;
    if (location.phone) {
      const location = inventoryLocations[i].location;
      const phoneNumberElm = document.querySelector(
        `.iia-id-${location.id} .iia-phone`
      );

      const formatted = libphonenumber.parsePhoneNumber(
        location.phone,
        location.country
      );
      if (formatted) {
        phoneNumberElm.innerHTML = clickablePhoneNumber(
          settings,
          location.phone,
          formatted.formatNational()
        );
      } else {
        phoneNumberElm.innerHTML = clickablePhoneNumber(
          settings,
          location.phone
        );
      }
    }
  }
}

export function clickablePhoneNumber(settings, phoenNumber, formattedNumber) {
  if (!phoenNumber) {
    return '';
  }
  const displayNumber = formattedNumber || phoenNumber;

  if (settings.clickablePhone && isMobile()) {
    return `<a href=tel:${phoenNumber}>${displayNumber}</a>`;
  } else {
    return displayNumber;
  }
}
