import { groupByLocations } from './mapper';
import sort from './common/sort';
import {
  buildContainer,
  buildGroupdByItem,
  buildGroupedByHeader
} from './uiElementBuilder';

import groupedByListTemplate from './templates/displayGroupedByList.html';
import groupedByListItemTemplate from './templates/displayGroupedByListItem.html';
import containerTemplate from './templates/displayList.html';

export function renderGroupedByLocation(
  variantLocations,
  settings,
  isGeoSearch = false,
  template = ''
) {
  return new Promise(function(resolve) {
    groupByLocations(variantLocations, settings).then(function(
      groupedByLocation
    ) {
      if (!groupedByLocation) {
        resolve('');
      }
      const locations = Object.values(groupedByLocation);
      const sortedLocations = isGeoSearch ? locations : sort(locations);
      const options = [];
      for (let i = 0; i < sortedLocations.length; i++) {
        const groupedItems = [];
        if (
          sortedLocations[i].variants &&
          sortedLocations[i].variants.length > 0
        ) {
          for (let j = 0; j < sortedLocations[i].variants.length; j++) {
            groupedItems.push(
              buildGroupdByItem(
                sortedLocations[i].variants[j],
                settings,
                settings.groupedByListItemTemplate || groupedByListItemTemplate
              )
            );
          }

          options.push(
            buildGroupedByHeader(
              sortedLocations[i].location,
              settings,
              groupedItems.join(''),
              settings.groupedByListTemplate || groupedByListTemplate
            )
          );
        }
      }
      const html =
        options.length > 0
          ? buildContainer(
              template || containerTemplate,
              `<div class="iia-list">${options.join('')}</div>`,
              settings,
              false
            )
          : '';
      resolve(html);
    });
  });
}
