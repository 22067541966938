export const europeanCountries = [
  {
    code: 'AD'
  },
  {
    code: 'AL'
  },
  {
    code: 'AT',
    isEu: true
  },
  {
    code: 'AX'
  },
  {
    code: 'BA'
  },
  {
    code: 'BE',
    isEu: true
  },
  {
    code: 'BG',
    isEu: true
  },
  {
    code: 'BY'
  },
  {
    code: 'CH',
    isEea: true
  },
  {
    code: 'CY',
    isEu: true
  },
  {
    code: 'CZ',
    isEu: true
  },
  {
    code: 'DE',
    isEu: true
  },
  {
    code: 'DK',
    isEu: true
  },
  {
    code: 'EE',
    isEu: true
  },
  {
    code: 'ES',
    isEu: true
  },
  {
    code: 'FI',
    isEu: true
  },
  {
    code: 'FO'
  },
  {
    code: 'FR',
    isEu: true
  },
  {
    code: 'GB'
  },
  {
    code: 'GG'
  },
  {
    code: 'GR',
    isEu: true
  },
  {
    code: 'HR',
    isEu: true
  },
  {
    code: 'HU',
    isEu: true
  },
  {
    code: 'IE',
    isEu: true
  },
  {
    code: 'IM'
  },
  {
    code: 'IC',
    isEea: true
  },
  {
    code: 'IT',
    isEu: true
  },
  {
    code: 'JE'
  },
  {
    code: 'LI',
    isEea: true
  },
  {
    code: 'LT',
    isEu: true
  },
  {
    code: 'LU',
    isEu: true
  },
  {
    name: 'Latvia',
    code: 'LV',
    isEu: true
  },
  {
    code: 'MC'
  },
  {
    code: 'MD'
  },
  {
    code: 'MK'
  },
  {
    code: 'MT',
    isEu: true
  },
  {
    code: 'NL',
    isEu: true
  },
  {
    code: 'NO',
    isEea: true
  },
  {
    code: 'PL',
    isEu: true
  },
  {
    code: 'PT',
    isEu: true
  },
  {
    code: 'RO',
    isEu: true
  },
  {
    code: 'RU'
  },
  {
    code: 'SE',
    isEu: true
  },
  {
    code: 'SI',
    isEu: true
  },
  {
    code: 'SJ'
  },
  {
    name: 'Slovakia',
    code: 'SK',
    isEu: true
  },
  {
    code: 'SM'
  },
  {
    code: 'UA'
  },
  {
    code: 'VA'
  }
];

export const euCountries = europeanCountries
  .filter(c => c.isEu)
  .map(c => c.code);

export const euAndEeaCountries = europeanCountries
  .filter(c => c.isEu || c.isEea)
  .map(c => c.code);
