import { fireEvent } from './utils';
export const PAGE_URL_CHANGED_EVENT = 'inventoryInfo.pageUrlChanged';

export default function replaceStatePatch(history) {
  const replaceState = history.replaceState;
  history.replaceState = function() {
    try {
      fireEvent(PAGE_URL_CHANGED_EVENT);
      // eslint-disable-next-line no-empty
    } finally {
    }
    return replaceState.apply(history, arguments);
  };
}
