import { CssClasses } from './domain';
import { isMobile } from './utils';
import trackUsage, { USAGE_KEYS } from './common/trackUsage';
export default function expandable(
  settings,
  productIdentifier,
  parentContainer
) {
  if (!expandableAllowed(settings)) {
    return;
  }
  const elementContainer = parentContainer || document;
  const handle = productIdentifier.handle;
  if (settings.expandable) {
    window.inventoryInfo.expanded = window.inventoryInfo.expanded || {};

    window.inventoryInfo.expanded[handle] =
      window.inventoryInfo.expanded[handle] === undefined
        ? settings.initExpand && !isMobile()
        : window.inventoryInfo.expanded[handle];
    const clickables = elementContainer.querySelectorAll(CssClasses.clickable);
    const chevron = elementContainer.querySelector(CssClasses.chevron);
    const container = elementContainer.querySelector(
      CssClasses.detailsContainer
    );

    if (window.inventoryInfo.expanded[handle]) {
      showChevronUp(chevron);
      show(container);
    }
    for (let index = 0; index < clickables.length; index++) {
      const clickable = clickables[index];
      clickable.addEventListener('click', function() {
        window.inventoryInfo.expanded[handle] = !window.inventoryInfo.expanded[
          handle
        ];
        if (window.inventoryInfo.expanded[handle]) {
          showChevronUp(chevron);
          show(container);
          trackUsage(USAGE_KEYS.widgetExpanded);
        } else {
          chevron.classList.remove(CssClasses.chevronUp);
          chevron.classList.add(CssClasses.chevronDown);
          hide(container);
        }
      });
    }
  }
}
export function expandableAllowed(settings) {
  return settings.expandable && !settings.drawer;
}
function showChevronUp(chevron) {
  chevron.classList.remove(CssClasses.chevronDown);
  chevron.classList.add(CssClasses.chevronUp);
}
function show(elem) {
  // Get the natural height of the element
  const getHeight = function() {
    elem.style.display = 'block'; // Make it visible
    const height = elem.scrollHeight + 'px'; // Get it's height
    elem.style.display = ''; //  Hide it again
    return height;
  };

  const height = getHeight(); // Get the natural height
  elem.classList.add(CssClasses.display); // Make the element visible
  elem.style.height = height; // Update the max-height

  //Once the transition is complete, remove the inline max-height so the content can scale responsively
  window.setTimeout(function() {
    elem.style.height = '';
  }, 350);
}

function hide(elem) {
  // Give the element a height to change from
  elem.style.height = elem.scrollHeight + 'px';

  // Set the height back to 0
  window.setTimeout(function() {
    elem.style.height = '0';
  }, 1);

  // When the transition is complete, hide it
  window.setTimeout(function() {
    elem.classList.remove('is-visible');
  }, 350);
}
