import atcButton from './common/atcButton';
import { CONTAINER_ID } from './ui';
import { xhrIntercept } from './cart';
import { updateCart } from './common/api';
import { FulfillmentRule } from './domain';

import watchHiddenInputChange from './hiddenVariantIdWatcher';

const COOKIE_KEY = 'inventoryInfo_app_selected_location';
let isTrackingCart = false;
export default function setupLocationSelection(settings, parentContainer) {
  if (!isLocationSelectionEnabled(settings)) {
    return;
  }

  let selectedVairant = window.inventoryInfo.product.selectedVairant;
  if (!isTrackingCart) {
    isTrackingCart = true;
    watchHiddenInputChange(settings, parentContainer, function(variant) {
      selectedVairant = variant;
    });
    xhrIntercept('/cart/add', function() {
      updateCartLocationId(settings, getSelectedLocation(), selectedVairant);
    });
  }
  window.inventoryInfo.onLocationSelected = function(element) {
    setSelectedLocation(parseInt(element.value));
    if (settings.requireLocationSelection) {
      const button = atcButton.getAddToCartButton(settings, parentContainer);
      unblock(button);
    }
  };
  const selectedLocation = getSelectedLocation();
  if (selectedLocation) {
    const parentElement =
      parentContainer || document.querySelector(`#${CONTAINER_ID}`);

    const inputElement = parentElement.querySelector(
      `input[value="${selectedLocation}"]`
    );
    if (inputElement) {
      if (!inputElement.disabled) {
        inputElement.checked = true;
      } else {
        const button = atcButton.getAddToCartButton(settings, parentContainer);
        atcButton.blockLocationSelection(button);
      }
      return;
    }
  }

  if (settings.requireLocationSelection) {
    const button = atcButton.getAddToCartButton(settings, parentContainer);
    atcButton.blockLocationSelection(button);
  }
}

export function isLocationSelectionEnabled(settings) {
  return settings.fulfillmentRule === FulfillmentRule.SELECTED_LOCATION;
}
function unblock(button) {
  if (atcButton.isBlockedByLocationSelection(button)) {
    atcButton.unBlockLocationSelection(button);
  }
}

function getSelectedLocation() {
  const id = getCookie(COOKIE_KEY);
  return id ? parseInt(id) : undefined;
}

function setSelectedLocation(locationId) {
  document.cookie = `${COOKIE_KEY}=${locationId}`;
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function updateCartLocationId(settings, seletedLocation, selectedVairant) {
  if (seletedLocation) {
    if (settings.legacyMove) {
      updateCart({
        attributes: { '__inventory-location-app': seletedLocation }
      });
    } else {
      updateCart({
        attributes: {
          [`__inventory-location-app:${selectedVairant}`]: seletedLocation
        }
      });
    }
  }
}
