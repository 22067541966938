import atcButton from './common/atcButton';
import { getLocationsInStock } from './utils';
export default function trackLocationsInLineItemProps(
  identifier,
  settings,
  variantLocations,
  parentContainer
) {
  if (!settings.lineItemLocations) {
    return;
  }
  if (variantLocations && variantLocations.length > 0) {
    const form = atcButton.getCartForm(settings, parentContainer);

    if (form) {
      const locationsInStock = getLocationsInStock(
        identifier,
        variantLocations
      );
      if (locationsInStock && locationsInStock.length > 0) {
        const locationNames = locationsInStock.map(function(l) {
          return l.name;
        });

        const existingPropField = form.querySelector(
          'input[data-is-invetory-app="true"]'
        );

        if (existingPropField) {
          existingPropField.value = locationNames;
        } else {
          form.insertAdjacentHTML(
            'beforeend',
            `<input type="hidden" name="properties[__inventory_app_locations]" data-is-invetory-app="true" value="${locationNames}" />`
          );
        }
      }
    }
  }
}
