import { getCurrentShopLocale } from './common/commonUtils';
import { HtmlFragments, Tokens, wrapTextTemplatesWithHtml } from './domain';
export default function stockMessage(rules, name, qty) {
  const currentShopLocale = getCurrentShopLocale();
  const displayQty = qty < 0 ? 0 : qty;
  for (let i = 0; i < rules.length; i++) {
    const expression = createExpression(rules[i], qty);

    if (eval(expression)) {
      let threshold =
        rules[i].threshold_locales &&
        rules[i].threshold_locales[currentShopLocale]
          ? rules[i].threshold_locales[currentShopLocale]
          : rules[i].threshold;

      rules[i].titleMsg = wrapTextTemplatesWithHtml(rules[i].msg)
        .replace(Tokens.locationName, name)
        .replace(Tokens.name, name)
        .replace(Tokens.qty, displayQty)
        .replace(
          Tokens.threshold,
          threshold ? threshold.replace(Tokens.qty, qty) : ''
        )
        .replace(
          Tokens.thresholdColorStyle,
          rules[i].thresholdColor
            ? HtmlFragments.thresholdColorStyle.replace(
                Tokens.thresholdColor,
                rules[i].thresholdColor
              )
            : ''
        );

      return rules[i];
    }
  }
}

function createExpression(rule, qty) {
  return `${qty} ${rule.operator} ${rule.qty}`;
}

//Sample rules
// [
//   {
//     expression: `${Tokens.qty} === 0`,
//     msg: `${Tokens.locationName} - Sold out`
//   },
//   {
//     expression: `${Tokens.qty} === 1`,
//     msg: `${Tokens.locationName} - Low stock`
//   },
//   {
//     expression: `${Tokens.qty} > 1`,
//     msg: `${Tokens.locationName} - High stock`
//   }
// ];
