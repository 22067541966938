import atcButton from './common/atcButton';
export default function watchHiddenInputChange(
  settings,
  parentContainer,
  onInputChanged
) {
  const form = atcButton.getCartForm(settings, parentContainer);
  if (form) {
    const variantElement =
      form.querySelector('input[name="id"]') || form.elements.namedItem('id');
    if (!variantElement) {
      atcButton.logMissingElement('Unable to find hidden id input field ');
      return;
    }

    let variantValue = variantElement.value;

    window.setInterval(function() {
      if (variantElement.value !== variantValue) {
        variantValue = variantElement.value;
        onInputChanged(parseInt(variantValue));
      }
    }, 300);
  }
}

export function getSelectedvariant(settings, parentContainer) {
  if (settings) {
    const form = atcButton.getCartForm(settings, parentContainer);
    if (form) {
      const variantElement =
        form.querySelector('input[name="id"]') || form.elements.namedItem('id');

      return variantElement ? parseInt(variantElement.value) : undefined;
    }
  }
}
