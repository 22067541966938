export default function applyLocationPriority(settings, inventoryLocations) {
  // see if we have a location with primary
  const primaryLocation = inventoryLocations.find(
    inventoryLocation => inventoryLocation.location.isPrimary
  );

  if (primaryLocation) {
    if (primaryLocation.quantity > 0 || inventoryLocations.length === 1) {
      return [primaryLocation];
    }

    const index = inventoryLocations.indexOf(primaryLocation);
    inventoryLocations.splice(index, 1);
  }

  return inventoryLocations;
}
