import { hasUntrackedOnlineLocation } from './createOnlineStoreLocation';

export function hideForSoldOut(currentVariant, settings) {
  if (settings.showOnSoldOut) {
    return false;
  }
  if (
    hasUntrackedOnlineLocation(settings) &&
    !currentVariant.inventoryManagement
  ) {
    return false;
  }
  return isSoldOut(currentVariant);
}

export function isSoldOut(currentVariant) {
  const inStock = currentVariant.inventoryLocations.find(
    inventoryLocation => inventoryLocation.quantity > 0
  );

  return !inStock;
}
