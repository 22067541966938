import { insertDrawerContainer, openDrawer } from './common/commonUi';
import { getCurrentShopLocale, selectedLocation } from './common/commonUtils';
import { CssClasses, Tokens, DefautlColors } from './domain';
import drawerLink from './templates/drawerLink.html';
import { createUi } from './ui';
import { getWidget } from './widget';

export function showAvailabilityDrawerLink(
  currentVariant,
  data,
  productIdentifier,
  parentContainer
) {
  buildDrawer(currentVariant, data, productIdentifier, parentContainer);
}

export function showGroupedByAvailabilityDrawerLink(
  data,
  productIdentifier,
  parentContainer
) {
  buildDrawer(undefined, data, productIdentifier, parentContainer);
}

export function hasValidLocationsForDrawer(
  variantLocations,
  hideSoldOut,
  hiddenLocation
) {
  return (
    variantLocations.filter(function(variantLocation) {
      return variantLocation.inventoryLocations.find(inventoryLocation => {
        if (hiddenLocation && hiddenLocation[inventoryLocation.location.id]) {
          return false;
        }
        if (hideSoldOut && inventoryLocation.quantity <= 0) {
          return false;
        }

        return true;
      });
    }).length > 0
  );
}
function buildDrawer(currentVariant, data, productIdentifier, parentContainer) {
  insertDrawerContainer(currentVariant);
  const settings = data.settings;
  setupDrawerHandler(data, productIdentifier, currentVariant);
  let defaulLocationWidget = Promise.resolve({ html: '' });

  if (settings.defaultLocation && settings.defaultLocation.id) {
    const singleLocationInfo = getInventoryInfoForSingleLocation(
      data.variantLocations,
      selectedLocation() || settings.defaultLocation.id
    );
    const newData = Object.assign({}, data, {
      variantLocations: singleLocationInfo
    });

    defaulLocationWidget = getWidget(
      newData,
      productIdentifier,
      null,
      false,
      null,
      true,
      true,
      true
    );
  }
  defaulLocationWidget.then(function(widget) {
    const html = drawerLink
      .replace(Tokens.drawerLabel, getDrawerLabel(settings))
      .replaceAll(
        Tokens.iconcolor,
        settings.iconColor || DefautlColors.iconColor
      )
      .replaceAll(Tokens.defaultLocation, widget.html || '');
    createUi(settings, html, parentContainer);
  });
}

function getDrawerLabel(settings) {
  const currentShopLocale = getCurrentShopLocale();
  if (
    settings.drawerLabel_locales &&
    settings.drawerLabel_locales[currentShopLocale]
  ) {
    return settings.drawerLabel_locales[currentShopLocale];
  }
  return settings.drawerLabel || 'Store Availability';
}

function setupDrawerHandler(data, productIdentifier, currentVariant) {
  window.inventoryInfo.showAvailabilityDrawer = function(ev) {
    ev.preventDefault();
    const settings = data.settings;
    if (settings.geo && settings.geo.enabled && settings.geo.apiKey) {
      window.inventoryInfo.geo.createInput(
        function(inputHtml) {
          return new Promise(function(resolve) {
            document.querySelector(
              `.${CssClasses.drawerBody}`
            ).innerHTML = inputHtml;
            resolve();
          });
        },
        data,
        productIdentifier
      );
    } else {
      getWidget(data, productIdentifier, currentVariant).then(function(widget) {
        document.querySelector(`.${CssClasses.drawerBody}`).innerHTML =
          widget.html || '';
      });
    }
    openDrawer();

    return false;
  };
}

function getInventoryInfoForSingleLocation(variantLocations, locationId) {
  return variantLocations.reduce(function(accumulator, variantLocation) {
    const filteredLocation = variantLocation.inventoryLocations.filter(function(
      inventoryLocation
    ) {
      return inventoryLocation.location.id === locationId;
    });
    accumulator.push(
      Object.assign({}, variantLocation, {
        inventoryLocations: filteredLocation
      })
    );
    return accumulator;
  }, []);
}
