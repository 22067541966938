// const customerTagRules = {
//   blockOutOfStock: true,
//   tags: {
//     Newtown: { locations: [54556852389] },
//     KS: { locations: [54556852389] },
//     CO: { locations: [54556852389] }
//   }
// };

import atcButton from './common/atcButton';
import { toggleAddToCartButton } from './common/commonUtils';

export default function applyCustomerTagRules(
  inventoryLocations,
  settings,
  parentContainer,
  button
) {
  const customerTagRules = getCustomerTagRules(settings);
  if (!customerTagRules) {
    return inventoryLocations;
  }
  const validLocationsByTag = getValidLocationsByTag(
    inventoryLocations,
    customerTagRules.tags
  );

  if (!button) {
    button = atcButton.getAddToCartButton(settings, parentContainer);
  }

  if (customerTagRules.blockOutOfStock && button) {
    blockPurchaseIfOutOfStock(validLocationsByTag, button, settings);
  }

  return validLocationsByTag;
}

export function getLocationsByCustomerTag(inventoryLocations, settings) {
  const customerTagRules = getCustomerTagRules(settings);
  if (!customerTagRules) {
    return inventoryLocations;
  }
  return getValidLocationsByTag(inventoryLocations, customerTagRules.tags);
}
export function getValidLocationsByTag(inventoryLocations, tags) {
  const validLocationsByTag = [];
  const tagRule = getMatchingTagRule(tags);

  for (let i = 0; i < inventoryLocations.length; i++) {
    if (isValid(inventoryLocations[i], tagRule)) {
      validLocationsByTag.push(inventoryLocations[i]);
    }
  }

  return validLocationsByTag;
}

function isValid(inventoryLocation, tagRule) {
  if (tagRule && tagRule.locations && tagRule.locations.length > 0) {
    return tagRule.locations.indexOf(inventoryLocation.location.id) >= 0;
  }
  return false;
}

export function getMatchingTagRule(customerTagRules) {
  const taggeLocations = { locations: [] };
  for (let i = 0; i < window.inventoryInfo.customerTags.length; i++) {
    const lowerCaseTag = window.inventoryInfo.customerTags[i].toLowerCase();
    const tag = window.inventoryInfo.customerTags[i];

    if (customerTagRules[lowerCaseTag] || customerTagRules[tag]) {
      const tagRule = customerTagRules[lowerCaseTag] || customerTagRules[tag];

      taggeLocations.locations.push.apply(
        taggeLocations.locations,
        tagRule.locations
      );
    }
  }

  return taggeLocations;
}

function blockPurchaseIfOutOfStock(validLocationsByTag, button, settings) {
  const inStockLocations = validLocationsByTag.filter(function(locationByTag) {
    return locationByTag && locationByTag.quantity > 0;
  });

  if (!inStockLocations || inStockLocations.length === 0) {
    toggleAddToCartButton(button, true, settings);
  } else if (atcButton.isOutOfStockPurchaseBlocked(button)) {
    toggleAddToCartButton(button, false, settings);
  }
}

function getCustomerTagRules(settings) {
  if (!settings.stockLocationRules) {
    return;
  }
  const customerTagRules = settings.stockLocationRules.customerTagRules;
  if (
    !customerTagRules ||
    !customerTagRules.tags ||
    Object.keys(customerTagRules.tags).length === 0 ||
    !window.inventoryInfo.customerTags ||
    window.inventoryInfo.customerTags.length === 0
  ) {
    return;
  }
  return customerTagRules;
}
