const INVENTORY_APP_PREVIEW = 'inventory-app-preview';

export function setUpPreview() {
  isPreview();
}
export function isPreview() {
  if (window.Shopify && window.Shopify.designMode) {
    return true;
  }
  if (createDevSession()) {
    document.cookie = `${INVENTORY_APP_PREVIEW}=true;path=/`;
    return true;
  }
  return document.cookie.indexOf(INVENTORY_APP_PREVIEW) >= 0;
}

function createDevSession() {
  return (
    window.location.search.toLocaleLowerCase().indexOf(INVENTORY_APP_PREVIEW) >=
    0
  );
}
