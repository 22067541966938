import { getIncoming } from './common/api';
import {
  CssClasses,
  DefaulText,
  InventoryDisplayOption,
  Tokens
} from './domain';
import { isSoldOut } from './soldOut';
import incomingListItem from './templates/incomingListItem.html';
import incomingStock from './templates/incomingStock.html';
import { getCurrentShop } from './common/commonUtils';
import { openDrawer } from './common/commonUi';

export function showIncomingStock(currentVariant, settings) {
  if (
    !settings.drawer &&
    window.inventoryInfo.product &&
    settings.incomingStock &&
    hasIncoming(currentVariant, settings)
  ) {
    if (
      isSoldOut(currentVariant) ||
      shouldAlwaysShow(settings) ||
      settings.incomingStockDisplay === InventoryDisplayOption.HasIncoming
    ) {
      setupIncomingHandler(currentVariant, settings);
      return true;
    }
  }
  return false;
}

export function getIncomingStockLinkHtml(settings) {
  return incomingStock
    .replace(
      Tokens.incomingStockLink,
      settings.incomingStockLink || 'View incoming inventory'
    )
    .replace(Tokens.incomingStockNote, settings.incomingStockNote || '');
}

function setupIncomingHandler(currentVariant, settings) {
  window.inventoryInfo.showIncoming = function() {
    openDrawer();

    getIncoming(getCurrentShop(), currentVariant.variant).then(function(data) {
      document.querySelector(
        `.${CssClasses.drawerBody}`
      ).innerHTML = buildIncomingList(data, settings, currentVariant.variant);
    });
  };
}

function buildIncomingList(data, settings, variantId) {
  const incomingInventory = data.incomingInventory;
  let listHtml = '';
  for (let i = 0; i < incomingInventory.length; i++) {
    if (settings.hideZeroIncoming && incomingInventory[i].incoming <= 0) {
      continue;
    }

    const itemHtml = incomingListItem
      .replace(Tokens.locationName, incomingInventory[i].location.name)
      .replace(
        Tokens.titleText,
        getIncomingStockTitle(incomingInventory[i], settings, variantId)
      );
    listHtml += itemHtml;
  }
  return (
    listHtml ||
    '<div class="iia-no-incoming">No Locations have incoming inventory.</div>'
  );
}

function getIncomingStockTitle(incomingInventoryItem, settings, variantId) {
  const titleTemplate = settings.incomingTitleTemplate || DefaulText.Incoming;
  return titleTemplate
    .replace(Tokens.qty, incomingInventoryItem.incoming)
    .replace(Tokens.date, getIncomingDate(variantId));
}

function getIncomingDate(variantId) {
  if (window.inventoryInfo.product && window.inventoryInfo.product.variants) {
    const selectedVariant = window.inventoryInfo.product.variants.find(function(
      v
    ) {
      return v.id === variantId;
    });
    return selectedVariant && selectedVariant.incomingDate
      ? new Date(selectedVariant.incomingDate).toDateString()
      : '';
  }
  return '';
}

function hasIncoming(currentVariant, settings) {
  return (
    shouldAlwaysShow(settings) ||
    window.inventoryInfo.product.variants.find(
      v => v.id === currentVariant.variant
    ).incoming
  );
}

function shouldAlwaysShow(settings) {
  return (
    settings.alwaysShowIncoming ||
    settings.incomingStockDisplay === InventoryDisplayOption.Alwyas
  );
}
