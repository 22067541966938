import { getCart } from './common/api';

export function trackCartChanges() {
  getCart().then(function(cart) {
    updateCurrentCart(cart.items);
  });
  xhrIntercept('/cart/add', function() {
    fetchAndUpdateCurrentCart();
  });
  xhrIntercept('/cart/change', function() {
    fetchAndUpdateCurrentCart();
  });
}

function fetchAndUpdateCurrentCart() {
  getCart().then(function(cart) {
    updateCurrentCart(cart.items);
  });
}

function updateCurrentCart(items) {
  window.inventoryInfo = window.inventoryInfo || {};
  window.inventoryInfo.currentCart = window.inventoryInfo.currentCart || {};
  const duplicateTracker = {};
  for (let i = 0; i < items.length; i++) {
    const id = items[i].id;
    let qty = items[i].quantity;
    if (id in duplicateTracker) {
      //add the qty from the previous item
      qty += duplicateTracker[id];
    }
    duplicateTracker[id] = qty;
    window.inventoryInfo.currentCart[id] = qty;
  }
}
export function xhrIntercept(urlmatch, callback) {
  const { send } = XMLHttpRequest.prototype;
  XMLHttpRequest.prototype.send = function() {
    this.addEventListener(
      'readystatechange',
      function() {
        try {
          if (
            this.responseURL &&
            this.responseURL.includes(urlmatch) &&
            this.readyState === 4
          ) {
            callback(JSON.parse(this.response));
          }
        } catch (err) {
          logError(err);
        }
      },
      false
    );
    send.apply(this, arguments);
  };
  const originalFetch = window.fetch;
  window.fetch = function() {
    return originalFetch.apply(this, arguments).then(res => {
      try {
        if (res.url.includes(urlmatch) && res.status === 200) {
          res
            .clone()
            .json()
            .then(function(body) {
              callback(body);
            });
        }
      } catch (err) {
        logError(err);
      }
      return res;
    });
  };
}

function logError(err) {
  console.log(`Error in Intercepting Add to cart request: ${err}`);
}
