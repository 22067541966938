export default function shouldHide(settings) {
  let hide = false;
  if (
    settings.hiddenCollections &&
    settings.hiddenCollections.length > 0 &&
    window.inventoryInfo.product &&
    window.inventoryInfo.product.collections
  ) {
    for (let i = 0; i < settings.hiddenCollections.length; i++) {
      if (
        window.inventoryInfo.product.collections.indexOf(
          settings.hiddenCollections[i]
        ) >= 0
      ) {
        hide = true;
      }
    }
    return settings.collectionDisplayOnly ? !hide : hide;
  }

  return hide;
}
