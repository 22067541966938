import getCurrentVariant from './common/getCurrentVariant';
import { DefaulText, Tokens } from './domain';
import createNativeToast from './nativeToast';
import { getProductTitle } from './utils';
import { getValidLocationsByTag } from './customerTagRules';
import { isAllowedToOverSell } from './common/commonUtils';

export default function blockOverSelling(
  e,
  productIdentifier,
  newQtyOrQtySelector
) {
  e = e || window.event;

  if (!productIdentifier) {
    const button = e.target;
    if (button) {
      const handle = button.dataset.productHandle;
      const variant = button.dataset.productVariant;
      const productId = button.dataset.productId;
      if (handle && variant) {
        productIdentifier = {
          handle,
          variant: parseInt(variant),
          productId: parseInt(productId)
        };
      }
    }
  }
  if (!productIdentifier) {
    return;
  }
  const settings = getSettings(productIdentifier);

  if (!settings.stockLocationRules) {
    return;
  }
  const customerTagRules = settings.stockLocationRules.customerTagRules;

  if (!customerTagRules) {
    return;
  }

  const variant = getVariant(productIdentifier);

  if (!variant) {
    return;
  }

  if (isAllowedToOverSell(variant)) {
    return;
  }

  if (
    blockCustomerOverSelling(
      variant,
      customerTagRules,
      settings,
      newQtyOrQtySelector
    )
  ) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.preventDefault();
  }
}

function blockCustomerOverSelling(
  variant,
  customerTagRules,
  settings,
  newQtyOrQtySelector
) {
  const locationsForTag = getValidLocationsByTag(
    variant.inventoryLocations,
    customerTagRules.tags
  );

  return block(variant, locationsForTag, settings, newQtyOrQtySelector);
}

function block(variant, locations, settings, newQtyOrQtySelector) {
  const qtyToAdd = getQtyToAdd(settings, newQtyOrQtySelector);
  const totalRequestedQty = aggregateFromCart(
    settings,
    variant.variant,
    qtyToAdd
  );
  const totalInStock = locations.reduce(function(sum, location) {
    return location.quantity + sum;
  }, 0);

  if (totalRequestedQty > totalInStock) {
    const msg = buildMsg(settings, totalInStock, variant.variant);
    showMsg(msg);
    return true;
  }
  return false;
}

function getQtyToAdd(settings, newQtyOrQtySelector) {
  let qtyInputValue = 1;
  if (newQtyOrQtySelector) {
    if (isNaN(newQtyOrQtySelector)) {
      const qty = getQtyFromInput(newQtyOrQtySelector);
      if (qty) {
        qtyInputValue = parseInt(qty);
      }
    } else {
      qtyInputValue = newQtyOrQtySelector;
    }
  } else {
    if (settings.qtyInput) {
      const qty = getQtyFromInput(settings.qtyInput);
      if (qty) {
        qtyInputValue = parseInt(qty);
      }
    }
  }
  return qtyInputValue;
}

function getQtyFromInput(selector) {
  const qtyInput = document.querySelector(selector);
  if (qtyInput) {
    return qtyInput.value;
  }
}
function aggregateFromCart(settings, variant, qtyToAdd) {
  const qty = window.inventoryInfo.currentCart[variant] || 0;
  return qty + qtyToAdd;
}
function getVariant(productIdentifier) {
  let variantLocations = null;
  if (window.inventoryInfo[productIdentifier.handle]) {
    variantLocations =
      window.inventoryInfo[productIdentifier.handle].data.variantLocations;
  } else {
    const product = window.inventoryInfo.collection.products.find(function(p) {
      return p.id === productIdentifier.productId;
    });
    variantLocations = product.variantLocations;
  }
  if (!variantLocations) {
    return null;
  }
  const variant = getCurrentVariant(
    variantLocations,
    productIdentifier,
    variantLocations
  );
  return variant;
}

function getSettings(productIdentifier) {
  return (
    window.inventoryInfo.settings ||
    window.inventoryInfo[productIdentifier.handle].data.settings
  );
}

function buildMsg(settings, totalAvailable, variantId) {
  const product = getProductTitle(variantId);
  const msg = settings.atcBlockedMsg || DefaulText.AtcBlockedMsg;
  return msg
    .replace(Tokens.product, product)
    .replace(Tokens.qty, totalAvailable);
}

function showMsg(msg) {
  createNativeToast(function() {
    nativeToast({
      message: msg,
      type: 'error',
      position: 'north',
      timeout: 6000,
      rounded: true,
      edge: true
    });
  });
}
