import listTemplate from './templates/displayList.html';
import displayListWithOnlineLocation from './templates/displayListWithOnlineLocation.html';
import listItemTemplate from './templates/displayListItem.html';
import buildUiElements, { buildOptions } from './uiElementBuilder';
import { Tokens, Type } from './domain';
import { injectStyles } from './ui';
import { getCurrentShopLocale } from './common/commonUtils';
export default function displayList(
  currentVariant,
  inventoryLocations,
  onlineLocation,
  settings,
  customListTemplate,
  showAsChangeLocation
) {
  const locationSegementsTemplate =
    settings.templateType === Type.LOCATION_SEGMENTS;
  const template =
    customListTemplate ||
    (settings.containerTemplate ||
      (locationSegementsTemplate && onlineLocation
        ? displayListWithOnlineLocation
        : listTemplate));

  const currentShopLocale = getCurrentShopLocale();

  let optionTextTemplate =
    settings.optionTextTemplate_locales &&
    settings.optionTextTemplate_locales[currentShopLocale]
      ? settings.optionTextTemplate_locales[currentShopLocale]
      : settings.optionTextTemplate;

  const uiElement = buildUiElements(
    currentVariant,
    inventoryLocations,
    settings,
    settings.itemTemplate || listItemTemplate,
    template,
    optionTextTemplate,
    showAsChangeLocation
  );
  const isOnlineOnly = inventoryLocations.length === 0 && onlineLocation;
  return locationSegementsTemplate && onlineLocation
    ? insertOnlineLocation(
        uiElement,
        currentVariant,
        onlineLocation,
        settings,
        isOnlineOnly
      )
    : uiElement;
}

function insertOnlineLocation(
  uiElement,
  currentVariant,
  onlineLocation,
  settings,
  isOnlineOnly
) {
  const onlineOption = buildOptions(
    settings,
    currentVariant,
    [onlineLocation],
    settings.itemTemplate || listItemTemplate,
    settings.optionTextTemplate
  );
  let overrideSyles = '';
  if (!settings.onlineLocationHeader) {
    overrideSyles += '.iia-online-header {display:none;}';
  }
  if (isOnlineOnly) {
    overrideSyles += ' .iia-store-header {display:none;}';
  } else {
    overrideSyles += ' .iia-store-header {display:block;}';
  }

  if (overrideSyles) {
    injectStyles(overrideSyles);
  }

  const shopLocale = getCurrentShopLocale();
  let storeLocationHeaderText = settings.storeLocationHeader;
  if (
    settings.storeLocationHeader_locales &&
    Object.keys(settings.storeLocationHeader_locales).length > 0 &&
    settings.storeLocationHeader_locales[shopLocale]
  ) {
    storeLocationHeaderText = settings.storeLocationHeader_locales[shopLocale];
  }

  let onlineLocationHeaderText = settings.onlineLocationHeader;
  if (
    settings.onlineLocationHeader_locales &&
    Object.keys(settings.onlineLocationHeader_locales).length > 0 &&
    settings.onlineLocationHeader_locales[shopLocale]
  ) {
    onlineLocationHeaderText =
      settings.onlineLocationHeader_locales[shopLocale];
  }

  return uiElement
    .replace(Tokens.online, onlineOption)
    .replace(Tokens.storeLocationHeader, storeLocationHeaderText || 'In store')
    .replace(Tokens.onlineLocationHeader, onlineLocationHeaderText || '');
}
