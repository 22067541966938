// const sampleRule = {
//   stockLocationRules: {
//     // blockCountryPurchaseRules: [
//     //   {
//     //     locationId: 54556852389,
//     //     countryCode: 'GB'
//     //   }
//     // ],
//     blockOtherCountriesPurchaseRules: {
//       locationIds: [54556852389],
//       countryCodes: ['USA']
//     }
//   }
// };
import atcButton from './common/atcButton';
import { fireEvent } from './utils';
import { euCountries, euAndEeaCountries } from './eu';
import { toggleAddToCartButton, waitForCondition } from './common/commonUtils';

export function shouldAllowPurchase(inventoryLocations, settings, countryCode) {
  //For now pass an empty object for button, may be we will change to pass the checkout button?
  return blockCountryPurchase(
    inventoryLocations,
    settings,
    null,
    { dataset: {} },
    countryCode
  );
}
export default function blockCountryPurchase(
  inventoryLocations,
  settings,
  parentContainer,
  cartButton,
  countryCode
) {
  return new Promise(function(resolve) {
    getAssoicatedCountries(settings, countryCode).then(function(
      associatedCountries
    ) {
      if (!associatedCountries || associatedCountries.length === 0) {
        resolve(false);
        return;
      }

      const button =
        cartButton || atcButton.getAddToCartButton(settings, parentContainer);
      if (!button) {
        waitForCondition(
          'atcButton',
          function() {
            return atcButton.getAddToCartButton(settings, parentContainer);
          },
          function() {
            blockCountryPurchase(
              inventoryLocations,
              settings,
              parentContainer,
              cartButton,
              countryCode
            );
          }
        );
        resolve(false);
        return;
      }

      // Below is temp fix for items without inventory tracked
      if (
        inventoryLocations.find(
          location => location && location.quantity === null
        )
      ) {
        resolve(false);
        return;
      }

      const isOutOfStock = block(
        associatedCountries,
        inventoryLocations,
        button,
        settings
      );
      resolve(isOutOfStock);
    });
  });
}

function getCodeForMarkets(settings) {
  return settings.marketsCountryCode &&
    window.inventoryInfo.markets &&
    window.inventoryInfo.markets.isoCode
    ? window.inventoryInfo.markets.isoCode
    : settings.marketsCountryCode && window.Shopify.country;
}
function block(associatedCountries, inventoryLocations, button, settings) {
  let isOutOfStock = false;
  const locationsWithStock = getLocationsWithStock(
    associatedCountries,
    inventoryLocations,
    settings
  );

  if (locationsWithStock && locationsWithStock.length === 0) {
    toggleAddToCartButton(button, true, settings);
    isOutOfStock = true;
    fireLocationRuleEvent(true);
  } else if (atcButton.isOutOfStockPurchaseBlocked(button)) {
    toggleAddToCartButton(button, false, settings);
    fireLocationRuleEvent(false);
  }

  return isOutOfStock;
}

export function determineCountryCode(settings, countryCode) {
  return new Promise(function(resolve) {
    if (countryCode) {
      resolve(countryCode);
      return;
    }
    if (window.inventoryInfo.currentCountryCode) {
      resolve(window.inventoryInfo.currentCountryCode);
      return;
    }

    const marketCountryCode = getCodeForMarkets(settings);
    if (marketCountryCode) {
      window.inventoryInfo.currentCountryCode = marketCountryCode;
      resolve(window.inventoryInfo.currentCountryCode);
      return;
    }
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/browsing_context_suggestions.json');
    xhr.onload = function() {
      if (xhr.status === 200) {
        const context = JSON.parse(xhr.responseText);
        window.inventoryInfo.currentCountryCode =
          context.detected_values.country.handle;
        resolve(window.inventoryInfo.currentCountryCode);
      }
    };
    xhr.send();
  });
}

export function getLocationsWithStock(
  associatedCountries,
  inventoryLocations,
  settings
) {
  const locations = getInventoryLocationsForCountry(
    associatedCountries,
    inventoryLocations
  );

  const minQty = settings ? settings.minQty || 0 : 0;
  if (locations) {
    return locations.filter(location => location && location.quantity > minQty);
  }
  return null;
}

export function getInventoryLocationsForCountry(
  associatedCountries,
  inventoryLocations
) {
  return inventoryLocations.filter(function(inventoryLocation) {
    return associatedCountries.indexOf(inventoryLocation.location.id) >= 0;
  });
}

export function getLocationsForCurrentCountry(settings, inventoryLocations) {
  return new Promise(function(resolve) {
    if (!settings.showLocationByRules) {
      resolve(inventoryLocations);
      return;
    }
    getAssoicatedCountries(settings).then(function(associatedCountries) {
      if (!associatedCountries || associatedCountries.length === 0) {
        resolve(inventoryLocations);
        return;
      }
      const countryLocations = getInventoryLocationsForCountry(
        associatedCountries,
        inventoryLocations
      );
      resolve(countryLocations);
    });
  });
}

export function getStockLocationRules(settings) {
  if (!settings.stockLocationRules) {
    return;
  }
  const blockCountryPurchaseRules =
    settings.stockLocationRules.blockCountryPurchaseRules;

  if (!blockCountryPurchaseRules || blockCountryPurchaseRules.length === 0) {
    return;
  }

  return blockCountryPurchaseRules;
}
export function getAssoicatedCountries(settings, code) {
  return new Promise(function(resolve) {
    const blockCountryPurchaseRules = getStockLocationRules(settings);

    if (!blockCountryPurchaseRules) {
      resolve(null);
      return;
    }
    determineCountryCode(settings, code).then(function(code) {
      let associatedLocationIds = blockCountryPurchaseRules
        .filter(function(rule) {
          return (
            code === rule.countryCode || isInARegion(code, rule.countryCode)
          );
        })
        .map(bpr => bpr.locationId);

      // Check for Rest of the world
      if (associatedLocationIds.length === 0) {
        associatedLocationIds = blockCountryPurchaseRules
          .filter(function(rule) {
            return '__ROW__' === rule.countryCode;
          })
          .map(bpr => bpr.locationId);
      }
      resolve(associatedLocationIds);
    });
  });
}

function isInARegion(visitorCountryCode, regionCode) {
  if (regionCode === '__EU__') {
    return euCountries.indexOf(visitorCountryCode) >= 0;
  }

  if (regionCode === '__EEA__') {
    return euAndEeaCountries.indexOf(visitorCountryCode) >= 0;
  }
  return false;
}

export function fireLocationRuleEvent(outOfStock) {
  fireEvent(window.inventoryInfo.LOCATION_RULE_APPLIED, { outOfStock });
}
