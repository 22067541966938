import { insertDrawerContainer, openDrawer } from './common/commonUi';
import { getCurrentShopLocale } from './common/commonUtils';
import { CssClasses, Tokens, DefautlColors } from './domain';
import drawerLink from './templates/drawerLink.html';
import { createUi } from './ui';
import { getWidget } from './widget';

export function showAvailabilityDrawerLink(
  currentVariant,
  data,
  productIdentifier,
  parentContainer
) {
  insertDrawerContainer(currentVariant);
  const settings = data.settings;
  setupDrawerHandler(data, productIdentifier, currentVariant);
  const html = drawerLink
    .replace(Tokens.drawerLabel, getDrawerLabel(settings))
    .replaceAll(
      Tokens.iconcolor,
      settings.iconColor || DefautlColors.iconColor
    );
  createUi(settings, html, parentContainer);
}

function getDrawerLabel(settings) {
  const currentShopLocale = getCurrentShopLocale();
  if (
    settings.drawerLabel_locales &&
    settings.drawerLabel_locales[currentShopLocale]
  ) {
    return settings.drawerLabel_locales[currentShopLocale];
  }
  return settings.drawerLabel || 'Store Availability';
}

function setupDrawerHandler(data, productIdentifier, currentVariant) {
  window.inventoryInfo.showAvailabilityDrawer = function(ev) {
    ev.preventDefault();
    const settings = data.settings;
    if (settings.geo && settings.geo.enabled && settings.geo.apiKey) {
      window.inventoryInfo.geo.createInput(function(inputHtml) {
        return new Promise(function(resolve) {
          document.querySelector(
            `.${CssClasses.drawerBody}`
          ).innerHTML = inputHtml;
          resolve();
        });
      }, settings.geo);
    } else {
      getWidget(data, productIdentifier, currentVariant).then(function(widget) {
        document.querySelector(`.${CssClasses.drawerBody}`).innerHTML =
          widget.html || '';
      });
    }
    openDrawer();

    return false;
  };
}
