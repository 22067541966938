import {
  Tokens,
  HtmlFragments,
  DefaulText as DefaultText,
  wrapTextTemplatesWithHtml,
  CssClasses,
  DefautlColors,
  Type
} from './domain';
import { clickablePhoneNumber, formatPhoneNumbers } from './phoneNumbers';
import { getIncomingStockLinkHtml, showIncomingStock } from './incomingStock';
import listTemplate from './templates/displayList.html';

import stockMessage from './stockMessage';
import { isLocationSelectionEnabled } from './locationSelection';
import {
  getCurrentShopLocale,
  isAllowedToOverSell,
  selectedLocation
} from './common/commonUtils';
import { insertDrawerContainer } from './common/commonUi';
import { expandableAllowed } from './expandable';
export default function buildUiElements(
  currentVariant,
  inventoryLocations,
  settings,
  itemTemplate,
  containerTemplate,
  itemTextTemplate
) {
  const options = buildOptions(
    settings,
    currentVariant,
    inventoryLocations,
    itemTemplate,
    itemTextTemplate
  );
  const showIncoming = showIncomingStock(currentVariant, settings);
  if (showIncoming) {
    insertDrawerContainer(currentVariant);
  }
  return buildContainer(
    containerTemplate,
    settings.templateType === Type.LOCATION_SEGMENTS
      ? options
      : `<div class="iia-list">${options}</div>`,
    settings,
    showIncoming
  );
}

export function buildOptions(
  settings,
  currentVariant,
  inventoryLocations,
  itemTemplate,
  itemTextTemplate
) {
  let options = '';
  let template = getTextTemplate(itemTextTemplate, HtmlFragments.locationName);
  for (let i = 0; i < inventoryLocations.length; i++) {
    const location = inventoryLocations[i].location;
    const quantity =
      inventoryLocations[i].quantity < 0 ? 0 : inventoryLocations[i].quantity;
    let titleText;

    if (location.untracked) {
      template = getUntrackedMessageTemplate(settings.untrackedMessage);
    }

    if (hasStockThresholdConfig(settings) && !location.untracked) {
      const stockInfo = stockMessage(
        settings.stockMessageRules,
        location.name,
        inventoryLocations[i].quantity || 0
      );
      titleText = stockInfo ? stockInfo.titleMsg : HtmlFragments.stockMsgError;
    } else {
      titleText = template
        .replace(Tokens.locationName, location.name)
        .replace(Tokens.name, location.name)
        .replace(Tokens.qty, quantity);
    }
    options += itemTemplate
      .replaceAll(Tokens.locationId, location.id)
      .replaceAll(
        Tokens.titleText,
        wrapWithLabelFor(settings, location.id, titleText)
      )
      .replaceAll(Tokens.address1, location.address1 || '')
      .replaceAll(Tokens.address2, location.address2 || '')
      .replaceAll(Tokens.city, location.city || '')
      .replaceAll(Tokens.province, location.province || '')
      .replaceAll(Tokens.zip, location.zip || '')
      .replaceAll(Tokens.distance, getDistance(location))
      .replaceAll(
        Tokens.phone,
        !settings.formatPhone
          ? clickablePhoneNumber(settings, location.phone)
          : ''
      )
      .replaceAll(Tokens.info, location.info || '')
      .replaceAll(Tokens.note, getLocationNote(location, quantity))
      .replaceAll(
        Tokens.disabled,
        quantity > 0 || isAllowedToOverSell(currentVariant) ? '' : 'disabled'
      )
      .replaceAll(
        Tokens.selectLocationInfo,
        window.inventoryInfo.api.getSelectedLocationInfo
          ? window.inventoryInfo.api.getSelectedLocationInfo(
              location,
              selectedLocation()
            )
          : ''
      );
  }

  if (settings.formatPhone) {
    formatPhoneNumbers(settings, inventoryLocations);
  }
  return options;
}

export function buildGroupedByHeader(
  groupedItemHeader,
  settings,
  groupedItems,
  groupHeaderTemplate
) {
  return groupHeaderTemplate
    .replace(Tokens.headerTextTtemplate, groupedItemHeader.name)
    .replaceAll(Tokens.locationId, groupedItemHeader.id)
    .replace(Tokens.options, groupedItems)
    .replaceAll(Tokens.distance, getDistance(groupedItemHeader))
    .replaceAll(Tokens.note, getLocationNote(groupedItemHeader));
}

export function buildGroupdByItem(groupedItem, settings, groupItemTemplate) {
  const currentShopLocale = getCurrentShopLocale();
  let optionTextTemplate =
    settings.optionTextTemplate_locales &&
    settings.optionTextTemplate_locales[currentShopLocale]
      ? settings.optionTextTemplate_locales[currentShopLocale]
      : settings.optionTextTemplate;
  const textTemplate = getTextTemplate(optionTextTemplate, HtmlFragments.name);
  let titleText = '';
  const quantity = groupedItem.quantity < 0 ? 0 : groupedItem.quantity;
  if (hasStockThresholdConfig(settings)) {
    const stockInfo = stockMessage(
      settings.stockMessageRules,
      groupedItem.name,
      groupedItem.quantity || 0
    );
    titleText = stockInfo ? stockInfo.titleMsg : HtmlFragments.stockMsgError;
  } else {
    titleText = textTemplate
      .replace(Tokens.name, groupedItem.name)
      .replace(Tokens.locationName, groupedItem.name)
      .replace(Tokens.qty, quantity);
  }
  return groupItemTemplate.replace(Tokens.titleText, titleText);
}

export function buildContainer(
  containerTemplate,
  children,
  settings,
  showIncoming
) {
  const shopLocale = getCurrentShopLocale();

  let headerText =
    settings.header === undefined ? DefaultText.header : settings.header;
  if (settings.header) {
    headerText =
      settings.header_locales && settings.header_locales[shopLocale]
        ? settings.header_locales[shopLocale]
        : settings.header;
  }

  let descriptionText =
    settings.description === undefined
      ? DefaultText.Description
      : settings.description;
  if (settings.description) {
    descriptionText =
      settings.description_locales && settings.description_locales[shopLocale]
        ? settings.description_locales[shopLocale]
        : settings.description;
  }

  return containerTemplate
    .replace(Tokens.children, children)
    .replace(Tokens.options, children)
    .replace(Tokens.header, headerText)
    .replace(
      Tokens.expandable,
      expandableAllowed(settings) ? CssClasses.expandable : ''
    )
    .replace(Tokens.decription, descriptionText)
    .replace(
      Tokens.incomingStock,
      showIncoming ? getIncomingStockLinkHtml(settings) : ''
    )

    .replaceAll(
      Tokens.iconcolor,
      settings.iconColor || DefautlColors.iconColor
    );
}

export function buildWithListContainer(children, settings) {
  return buildContainer(
    settings.containerTemplate || listTemplate,
    children,
    settings,
    false
  );
}
function getTextTemplate(optionTextTemplate, defaultNameHtml) {
  return optionTextTemplate
    ? wrapTextTemplatesWithHtml(optionTextTemplate)
    : undefined ||
        `${defaultNameHtml} - ${HtmlFragments.qty} ${DefaultText.available}`;
}

function getLocationNote(location, quantity) {
  if (location.note) {
    const shopLocale = getCurrentShopLocale();
    let locationNote = location.note;
    if (
      location.note_locales &&
      Object.keys(location.note_locales).length > 0 &&
      location.note_locales[shopLocale]
    ) {
      locationNote = location.note_locales[shopLocale];
    }
    if (location.soldOutOnlyNote) {
      return quantity <= 0 ? locationNote : '';
    }
    return locationNote;
  }
  return '';
}

function getUntrackedMessageTemplate(untrackedMessage) {
  return untrackedMessage
    ? wrapTextTemplatesWithHtml(untrackedMessage)
    : undefined || `${HtmlFragments.locationName} - ${DefaultText.available}`;
}

function hasStockThresholdConfig(settings) {
  return (
    settings.threshold &&
    settings.stockMessageRules &&
    settings.stockMessageRules.length > 0
  );
}

function wrapWithLabelFor(settings, locationId, titleText) {
  if (isLocationSelectionEnabled(settings)) {
    return `<label class="iia-location-selector-for" for="selector-${locationId}">${titleText}</label>`;
  }
  return titleText;
}

function getDistance(location) {
  return location.distance && location.distance.text
    ? location.distance.text
    : '';
}
