import { updateCart } from './common/api';
import { getAssoicatedCountries } from './blockCountryPurchase';

export default function cartAndCheckoutValidation(settings) {
  if (!settings.cartValidation) {
    return;
  }
  getAssoicatedCountries(settings).then(function(associatedCountries) {
    if (associatedCountries && associatedCountries.length > 0) {
      updateCart({
        attributes: {
          __loc_inv_app: JSON.stringify({
            locationIds: associatedCountries,
            cartValidation: settings.cartValidation
          })
        }
      });
    }
  });
}
