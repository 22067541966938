export default function locationInfoDecorator(
  variantId,
  inventoryLocations,
  locationInfo
) {
  if (locationInfo && locationInfo.length > 0) {
    for (let i = 0; i < inventoryLocations.length; i++) {
      const info = locationInfo.find(function(info) {
        return (
          info.variantId === variantId &&
          info.locationId === inventoryLocations[i].location.id
        );
      });

      if (info) {
        inventoryLocations[i].location.info = info.soldOutOnly
          ? inventoryLocations[i].quantity <= 0
            ? info.text
            : undefined
          : info.text;
      }
    }
  }
}
