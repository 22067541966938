//om sai ram
import { get } from './common/api.js';
import { isProductPage, getProductIdentifiers } from './utils';
import { getCurrentShop, waitForElementOnDoc } from './common/commonUtils.js';
import replaceStatePatch, { PAGE_URL_CHANGED_EVENT } from './replaceStatePatch';
import watchHiddenInputChange, {
  getSelectedvariant
} from './hiddenVariantIdWatcher';
import render from './render';
import { isPreview, setUpPreview } from './isPreview';
import { fireEvent } from './utils';
import { trackCartChanges } from './cart';
import blockOverSelling from './blockOverSelling';
import { shouldAllowPurchase } from './blockCountryPurchase';
import cartAndCheckoutValidation from './cartAndCheckoutValidation';
import trackLocationsInLineItemProps from './trackLocationsInLineItemProps';
import { getWidget } from './widget.js';
function loadApp(identifier, cachedData, parentContainerSelector, loadViaApi) {
  const parentContainer = parentContainerSelector
    ? document.querySelector(parentContainerSelector)
    : undefined;
  window.inventoryInfo[identifier.handle] =
    window.inventoryInfo[identifier.handle] || {};

  window.inventoryInfo[identifier.handle].currentProduct = identifier;
  window.inventoryInfo.closeDrawer && window.inventoryInfo.closeDrawer();

  if (!cachedData) {
    get(getCurrentShop(), identifier.handle, identifier.id).then(function(
      data
    ) {
      window.inventoryInfo[identifier.handle].data = data;
      window.inventoryInfo[identifier.handle].variantLocations =
        data.variantLocations;
      if (!window.inventoryInfo.settings) {
        window.inventoryInfo.settings = data.settings;
      }
      fireEvent(window.inventoryInfo.APP_DATA_EVENT, {
        variantLocations: data.variantLocations,
        settings: data.settings
      });
      if (
        !window.inventoryInfo.hasAltVariantOnChange ||
        parentContainerSelector
      ) {
        setUpAltVariantOnChange(
          data.settings,
          identifier.handle,
          identifier.id,
          parentContainerSelector
        );
        window.inventoryInfo.hasAltVariantOnChange = true;
      } else if (data.settings && data.settings.historyRepatch) {
        listenFoVariantChange();
      }
      renderApp(data, identifier, parentContainer, loadViaApi);
    });
  } else {
    renderApp(cachedData, identifier, parentContainer, loadViaApi);
  }
}

function renderApp(data, identifier, parentContainer, loadViaApi) {
  if (data.settings && !data.settings.uiEnabled && !isPreview()) {
    return;
  }

  if (data.settings.apiLoadOnly && !loadViaApi) {
    return;
  }

  trackLocationsInLineItemProps(
    identifier,
    data.settings,
    data.variantLocations
  );
  cartAndCheckoutValidation(data.settings);
  setUpBlockOnOverSell(data.settings, identifier);
  render(data, identifier, parentContainer);
}

export function setUpBlockOnOverSell(settings, identifier) {
  if (settings && settings.blockOverSelling) {
    if (!window.inventoryInfo.isTrackingCart) {
      trackCartChanges();
      window.inventoryInfo.isTrackingCart = true;
    }
    window.inventoryInfo.api.blockOverSelling = function(e) {
      blockOverSelling(e, identifier);
    };
  }
}
function setUpAltVariantOnChange(
  settings,
  productHandle,
  id,
  parentContainerSelector
) {
  if (settings && settings.variantSelector) {
    var parentEelement = parentContainerSelector
      ? document.querySelector(parentContainerSelector)
      : document;
    const variantSelectors = parentEelement.querySelectorAll(
      settings.variantSelector
    );
    if (variantSelectors) {
      for (let i = 0; i < variantSelectors.length; i++) {
        handleChange(
          variantSelectors[i],
          productHandle,
          id,
          parentContainerSelector
        );
      }
    }
  } else if (settings && settings.onHiddenIdChange) {
    watchHiddenInputChange(settings, parentEelement, function(variant) {
      onVariantChanged(
        { handle: productHandle, id, variant },
        parentContainerSelector
      );
    });
  }
}

function handleChange(
  variantSelector,
  productHandle,
  id,
  parentContainerSelector
) {
  variantSelector.addEventListener('change', function() {
    window.setTimeout(function() {
      const identifier = {
        handle: productHandle,
        id,
        variant: ShopifyAnalytics.meta.selectedVariantId
          ? parseInt(ShopifyAnalytics.meta.selectedVariantId)
          : undefined
      };
      onVariantChanged(identifier, parentContainerSelector);
    });
  });
}

function onVariantChanged(identifier, parentContainerSelector) {
  if (
    window.inventoryInfo[identifier.handle].currentProduct &&
    window.inventoryInfo[identifier.handle].currentProduct.variant !==
      identifier.variant
  ) {
    if (
      window.inventoryInfo[identifier.handle].data.settings.groupedByLocation
    ) {
      return;
    }
    loadApp(
      identifier,
      window.inventoryInfo[identifier.handle].data,
      parentContainerSelector
    );
  }
}

function listenFoVariantChange() {
  //Set up Url Watcher which works for 95% of the themes
  //when monitoring for variant changes
  replaceStatePatch(window.history);
  document.addEventListener(PAGE_URL_CHANGED_EVENT, function() {
    window.setTimeout(function() {
      const identifier = getProductIdentifiers();

      onVariantChanged(identifier);
    });
  });
}

window.inventoryInfo = window.inventoryInfo || {};
window.inventoryInfo.api = window.inventoryInfo.api || {};
window.inventoryInfo.api.getProductIdentifiers = getProductIdentifiers;
window.inventoryInfo.api.shouldAllowPurchase = shouldAllowPurchase;
window.inventoryInfo.api.waitForElementOnDoc = waitForElementOnDoc;
window.inventoryInfo.api.getWidgetHtml = getWidget;

window.inventoryInfo.APP_LOADED_EVENT = 'inventoryInfo.appLoaded';
window.inventoryInfo.APP_RENDERED_EVENT = 'inventoryInfo.appRendered';
window.inventoryInfo.APP_DATA_EVENT = 'inventoryInfo.appData';

window.inventoryInfo.LOCATION_RULE_APPLIED =
  'inventoryInfo.locationRuleApplied';
window.inventoryInfo.api.loadApp = function(
  productIdentifier,
  parentContainerSelector
) {
  const cachedData = window.inventoryInfo[productIdentifier.handle]
    ? window.inventoryInfo[productIdentifier.handle].data
    : undefined;
  loadApp(productIdentifier, cachedData, parentContainerSelector, true);
};
window.inventoryInfo.api.trackVariantChanges = function(
  handle,
  parentContainerSelector,
  id
) {
  watchHiddenInputChange(
    window.inventoryInfo.settings,
    document.querySelector(parentContainerSelector),
    function(variant) {
      onVariantChanged({ handle, id, variant }, parentContainerSelector);
    }
  );
};
window.inventoryInfo.api.getSelectedvariant = function(
  parentContainerSelector
) {
  return getSelectedvariant(
    window.inventoryInfo.settings,
    document.querySelector(parentContainerSelector)
  );
};

window.inventoryInfo.api.showOnQuickView = function(
  productIdentifier,
  parentContainerSelector,
  timeoutInMs = 500
) {
  waitForElementOnDoc(
    parentContainerSelector,
    function() {
      window.inventoryInfo.api.loadApp(
        productIdentifier,
        parentContainerSelector
      );
      document.addEventListener(
        window.inventoryInfo.APP_RENDERED_EVENT,
        function() {
          window.inventoryInfo.api.trackVariantChanges(
            productIdentifier.handle,
            parentContainerSelector,
            productIdentifier.id
          );
        }
      );
    },
    300,
    timeoutInMs
  );
};
if (isProductPage()) {
  const identifier = getProductIdentifiers();
  loadApp(identifier);

  listenFoVariantChange();
}

setUpPreview();
if (BUILD_FOR_COLLECTION_PAGES) {
  // eslint-disable-next-line no-undef
  require('./collectionPages');
}
if (BUILD_FOR_CART_PAGE) {
  // eslint-disable-next-line no-undef
  require('./cartPage');
}
fireEvent(window.inventoryInfo.APP_LOADED_EVENT);

if (ENV === 'local') {
  // eslint-disable-next-line no-undef
  require('./inCountryRules/inCountryRules.js');
  // eslint-disable-next-line no-undef
  require('./geo/geo.js');
}
