export const CONTAINER_ID = 'inventory-info-app';
export const CONTAINER_CLASS = 'iia-container';
import atcButton from './common/atcButton';
import {
  isInDesignMode,
  waitForCondition,
  waitForElement
} from './common/commonUtils';
import trackUsage, { USAGE_KEYS } from './common/trackUsage';

export function createUi(settings, html, parentContainer) {
  return new Promise(function(resolve) {
    const containerSelector = `#${CONTAINER_ID}`;
    if (isInDesignMode() || settings.appSection) {
      // Will have to wait for element to load
      waitForElement(
        parentContainer || document,
        containerSelector,
        function() {
          if (isInDesignMode()) {
            console.log('Found the app embedd in Design mode....');
          } else if (settings.appSection) {
            console.log(
              'Rendering Multi Location Inventory Info app based on a delayed loading of app section '
            );
          }
          createUiInternal(settings, html, parentContainer).then(function() {
            if (!isInDesignMode()) {
              trackUsage(USAGE_KEYS.impression);
            }
            resolve();
          });
        },
        300,
        5000
      );
    } else {
      createUiInternal(settings, html, parentContainer).then(function() {
        trackUsage(USAGE_KEYS.impression);
        resolve();
      });
    }
  });
}

export function clearUi() {
  const appContainer = document.querySelector(`#${CONTAINER_ID}`);
  if (appContainer) {
    appContainer.innerHTML = '';
    return;
  }
}

export function injectStyles(css) {
  const styleNode = `<style type="text/css">${css}</style>`;
  document.querySelector('head').insertAdjacentHTML('beforeend', styleNode);
}

function createUiInternal(settings, html, parentContainer) {
  return new Promise(function(resolve) {
    const elementContainer = parentContainer || document;
    const appContainer =
      elementContainer.querySelector(`#${CONTAINER_ID}`) ||
      elementContainer.querySelector(`.${CONTAINER_CLASS}`);
    if (isInDesignMode() && !appContainer) {
      return;
    }
    if (appContainer) {
      appContainer.innerHTML = '';
      appContainer.insertAdjacentHTML('afterbegin', html);
      resolve();
      return;
    }

    let actionButton = atcButton.getAddToCartButton(settings, parentContainer);

    if (actionButton) {
      insertBeforeAtc(actionButton, html);
      resolve();
    } else {
      waitForCondition(
        'atcButtonRender',
        function() {
          actionButton = atcButton.getAddToCartButton(
            settings,
            parentContainer
          );
          return actionButton;
        },
        function() {
          if (actionButton) {
            insertBeforeAtc(actionButton, html);
            resolve();
          }
        },
        300,
        30000
      );
    }
  });
}

function insertBeforeAtc(actionButton, html) {
  actionButton.insertAdjacentHTML('beforebegin', wrapInContainerDiv(html));
}

function wrapInContainerDiv(html) {
  if (document.querySelectorAll(`#${CONTAINER_ID}`).length === 0) {
    return `<div id="${CONTAINER_ID}" class="iia-container" iia-is-default="true">${html}</div>`;
  }

  return '';
}
