import { clearUi } from './ui';
import sort from './common/sort';
import getCurrentVariant from './common/getCurrentVariant';
import displayList from './displayList';
import removeInvalidLocations from './common/removeInvalidLocations';
import createOnlineStoreLocation from './createOnlineStoreLocation';
import locationInfoDecorator from './locationInfoDecorator';
import { getLocationsForCurrentCountry } from './blockCountryPurchase';
import { getLocationsByCustomerTag } from './customerTagRules';
import netInventory from './netInventory';
import invalidLocationElement from './templates/invalidLocations.html';
import { Type } from './domain';
import applyLocationPriority from './locationsPriority';
import { hideForSoldOut } from './soldOut';
import { renderGroupedByLocation } from './renderGroupedBy';
import { isInDesignMode } from './common/commonUtils';
// data = {settings,variantLocations}
export function getWidget(
  data,
  productIdentifier,
  currentVariant = null,
  isGeoSearch = false,
  listTemplate = null,
  ignoreOnline = false,
  ignoreSold = false,
  showAsChangeLocation = false
) {
  return new Promise(function(resolve) {
    const settings = data.settings;
    const variantLocations = data.variantLocations;

    if (
      settings.groupedByLocation &&
      window.inventoryInfo.product &&
      !window.inventoryInfo.product.defaultVariantOnly
    ) {
      renderGroupedByLocation(
        variantLocations,
        settings,
        isGeoSearch,
        listTemplate
      ).then(function(html) {
        resolve({ html });
      });
      return;
    } else {
      if (!currentVariant) {
        currentVariant = getCurrentVariant(
          variantLocations,
          productIdentifier,
          settings
        );
      }
      if (
        !currentVariant ||
        (!ignoreSold &&
          hideForSoldOut(currentVariant, settings) &&
          !isGeoSearch)
      ) {
        resolve({ clear: true });
        return;
      }
      let validLocations = removeInvalidLocations(
        currentVariant.inventoryLocations,
        settings
      );

      let onlineLocation;
      if (!ignoreOnline) {
        onlineLocation = createOnlineStoreLocation(
          currentVariant.inventoryLocations,
          settings,
          currentVariant.inventoryManagement
        );

        if (shouldClear(validLocations, onlineLocation)) {
          resolve({ clear: true });
          return;
        }
      }

      if (window.inventoryInfo.api.getSelectedLocations) {
        validLocations = window.inventoryInfo.api.getSelectedLocations(
          validLocations,
          settings
        );
      }
      validLocations = getLocationsByCustomerTag(validLocations, settings);

      validLocations = applyLocationPriority(settings, validLocations);

      if (shouldClear(validLocations, onlineLocation)) {
        resolve({ clear: true });
        return;
      }

      getLocationsForCurrentCountry(settings, validLocations).then(function(
        availableLocations
      ) {
        if (window.inventoryInfo.api.filterLocations) {
          availableLocations = window.inventoryInfo.api.filterLocations(
            availableLocations,
            settings
          );
        }
        if (shouldClear(availableLocations, onlineLocation)) {
          resolve({ clear: true });
          return;
        }

        locationInfoDecorator(
          currentVariant.variant,
          availableLocations,
          data.locationInfo
        );

        netInventory(availableLocations, settings);

        const sortedLocations = isGeoSearch
          ? availableLocations
          : sort(availableLocations);
        if (
          !isGeoSearch &&
          onlineLocation &&
          settings.templateType !== Type.LOCATION_SEGMENTS
        ) {
          sortedLocations.splice(0, 0, onlineLocation);
        }
        resolve({
          html: buildHtml(
            currentVariant,
            sortedLocations,
            onlineLocation,
            settings,
            listTemplate,
            showAsChangeLocation
          )
        });
      });
    }
  });
}

function shouldClear(validLocations, onlineLocation) {
  if (onlineLocation || isInDesignMode()) {
    return false;
  }
  if (!validLocations || validLocations.length === 0) {
    clearUi();
    return true;
  }
  return false;
}
function buildHtml(
  currentVariant,
  variantLocations,
  onlineLocation,
  settings,
  listTemplate,
  showAsChangeLocation
) {
  if (
    !currentVariant.inventoryManagement &&
    isInDesignMode() &&
    !onlineLocation
  ) {
    return invalidLocationElement;
  }
  return !variantLocations || variantLocations.length === 0
    ? ''
    : displayList(
        currentVariant,
        variantLocations,
        onlineLocation,
        settings,
        listTemplate,
        showAsChangeLocation
      );
}
